import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import { darkTheme, lightTheme } from "./themes";
import SkillsView from "./views/skillsview";
import ExperienceView from "./views/experienceview";
import HomeView from "./views/homeview";
import { ThemeSwitch } from "./components/themeswitch";
import Footer from "./components/footer";

const StyledApp = styled.div`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export default function App() {
  const [theme, setTheme] = React.useState(lightTheme);

  const toggleTheme = () => {
    document.body.style.backgroundColor = theme.background;
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  const setBackground = () => {
    document.body.style.backgroundColor = theme.background;
  };

  useEffect(() => {
    setBackground();
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StyledApp>
          <Navbar>
            <ThemeSwitch onClick={toggleTheme} />
          </Navbar>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/skills" element={<SkillsView />} />
            <Route path="/experience" element={<ExperienceView />} />
          </Routes>
        </StyledApp>
      </BrowserRouter>
    </ThemeProvider>
  );
}
