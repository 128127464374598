import styled from "styled-components";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text.primary};
  font-size: 1.2rem;
  width: 100%;
`;

const Year = new Date().getFullYear();

export default function Footer() {
  return <StyledFooter>© Ruben Claessens - {Year}</StyledFooter>;
}
