import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledNavItem = styled(Link)`
  display: flex;
  text-decoration: none;
  font-size: 2.5rem;
  align-items: center;
  padding: 1rem;
  border-radius: 100vmax;
  transition: all 0.2s ease-in;
  color: ${(props) => props.theme.navbar};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.text.secondary};
  }
`;

interface NavItemProps {
  text: string;
}

export default function NavItem({ text }: NavItemProps) {
  return (
    <StyledNavItem to={text === "Home" ? "/" : text}>{text}</StyledNavItem>
  );
}
