import styled from "styled-components";
import { ExperienceCard } from "../components/card";
import Title from "../components/title";
import { workExperience, education } from "../data";

const View = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    gap: 1rem;
    padding: 2rem;
    height: fit-content;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: fit-content;
    padding: 2rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: fit-content;
    padding: 2rem;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    height: fit-content;
    padding: 2rem;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  gap: 4rem;
  color: ${(props) => props.theme.main};
`;

const ExperienceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${(props) => props.theme.background};
  padding: 1rem 2rem;
  gap: 1rem;
  width: 100%;
  max-width: 35ch;
  flex: 1;
  border-radius: 20px;
`;

const ExperienceTitle = styled.p`
  font-weight: bolder;
  margin: 0;
  font-size: 2rem;
  color: ${(props) => props.theme.text.primary};
`;

const ExperienceType = styled.p`
  margin: 0;
  font-size: 2rem;
  font-style: italic;
  color: ${(props) => props.theme.text.primary};
`;

const ExperienceYears = styled.p`
  margin: 0;
  font-size: 2rem;
  color: ${(props) => props.theme.text.primary};
`;

export default function ExperienceView() {
  return (
    <View>
      <Title>Experience</Title>
      <CardContainer>
        <ExperienceCard title="Education 🎓">
          {education.map((x) => (
            <ExperienceItem>
              <ExperienceTitle>{x.name}</ExperienceTitle>
              <ExperienceType>{x.type}</ExperienceType>
              <ExperienceYears>{x.years}</ExperienceYears>
            </ExperienceItem>
          ))}
        </ExperienceCard>
        <ExperienceCard title="Work 💼">
          {workExperience.map((x) => (
            <ExperienceItem>
              <ExperienceTitle>{x.name}</ExperienceTitle>
              <ExperienceType>{x.description}</ExperienceType>
              <ExperienceYears>{x.years}</ExperienceYears>
            </ExperienceItem>
          ))}
        </ExperienceCard>
      </CardContainer>
    </View>
  );
}
