export var frontend = [
  "HTML5",
  "CSS3",
  "JavaScript",
  "ReactJS",
  "Xamarin.Forms",
  "Splunk",
];

export var backend = [
  ".NET C#",
  "Python",
  "JavaScript",
  "SQL",
  "ASP.NET (Core)",
  "SQL Server",
  "SQLite",
  "Splunk",
];

export var toolsAndDesign = [
  "Visual Studio (Code)",
  "SQL Server Management Studio",
  "Adobe XD",
  "Visual Paradigm",
  "UML",
];

export var professionalSkills = [
  "Communicating",
  "Leading",
  "Problem solving",
  "Collaborating",
];

class School {
  public name: String;
  public type: String;
  public years: String;

  constructor(name: String, type: String, years: String) {
    this.name = name;
    this.type = type;
    this.years = years;
  }
}

class Work {
  public name: String;
  public description: String;
  public years: String;

  constructor(name: String, description: String, years: String) {
    this.name = name;
    this.description = description;
    this.years = years;
  }
}

export var education = [
  new School(
    "Bernardinus College - Heerlen",
    "High School - HAVO",
    "2015 - 2020"
  ),
  new School(
    "Zuyd Hogeschool - Heerlen",
    "Bachelor of Science - ICT Academy",
    "2020 - now"
  ),
];

export var workExperience = [
  new Work(
    "Enexis Netbeheer",
    "Editing circuit diagrams using AutoCad",
    "9-2021 - now"
  ),
  new Work(
    "Computerservice Kerkrade",
    "Helping clients with hardware and software problems. Installing new laptops, fixing wifi, etc.",
    "11-2020 - 3-2021"
  ),
  new Work(
    "Enexis Groep",
    "Creating a dashboard in Splunk to monitor the performance from Smallworld Applications",
    "2-2022 - 7-2022"
  ),
];
