import React from "react";
import {
  Email as EmailIcon,
  GitHub,
  LinkedIn,
  Public as PublicIcon,
} from "@mui/icons-material";
import styled from "styled-components";
import Footer from "../components/footer";
const me = require("../assets/images/me.jpeg");

const View = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10vh 10vw;
  height: 90vh;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    gap: 4rem;
    padding: 2rem;
    height: fit-content;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
    height: fit-content;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    height: fit-content;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    height: 90vh;
  }
`;

const HomeLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    align-items: center;
  }
`;

const Greeting = styled.h1`
  font-size: 2rem;
  padding: 2rem;
  border-radius: 50px 50px 10px 50px;
  background-color: ${(props) => props.theme.dark};
  color: ${(props) => props.theme.text.secondary};
`;

const Name = styled.h2`
  font-size: 6rem;
  margin: 2rem 0;
  color: ${(props) => props.theme.text.primary};

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-size: 3rem;
    margin: 2rem 0;
  }
`;

const Desciption = styled.p`
  font-size: 2rem;
  max-width: 35ch;
  font-weight: lighter;
  color: ${(props) => props.theme.text.primary};

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 0;
  }
`;

const Function = styled.span`
  font-size: calc(inherit + 10px);
  font-weight: 700;
  text-decoration: underline dotted;
  color: ${(props) => props.theme.text.primary};
`;

const Buttons = styled.div`
  display: flex;
  align-self: center;
  gap: 2rem;
`;

const IconButton = styled.a`
  margin-top: 10px;
  border-radius: 10px;
  color: ${(props) => props.theme.text.primary};
  transition: ease 0.3s;
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  text-decoration: none;
  outline: solid 1px ${(props) => props.theme.text.primary};

  & * {
    color: ${(props) => props.theme.text.primary};
  }

  &:hover {
    color: ${(props) => props.theme.text.secondary};
    background-color: ${(props) => props.theme.dark};
    transform: scale(1.1);
  }

  &:hover * {
    color: ${(props) => props.theme.text.secondary};
  }

  &:hover {
    color: #ffffff;
    background-color: ${(props) => props.theme.main};
    transform: scale(1.1);
  }
`;

const HomeRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 50%;
  height: 35rem;
  margin-bottom: 2rem;
  box-shadow: inset 0px 1px -10px ${(props) => props.theme.background};
`;

const Info = styled.h3`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${(props) => props.theme.text.primary};

  & a {
    text-decoration: none;
    color: ${(props) => props.theme.text.primary};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledEmailIcon = styled(EmailIcon)`
  color: ${(props) => props.theme.text.primary};
`;

const StyledPublicIcon = styled(PublicIcon)`
  color: ${(props) => props.theme.text.primary};
`;

export default function HomeView() {
  return (
    <React.Fragment>
      <View>
        <HomeLeft>
          <Greeting>Hello There! I'm</Greeting>
          <Name>Ruben Claessens</Name>
          <Desciption>
            A passionate <Function>software engineer</Function> who keeps going
            until the customer is satisfied. I receive a lot of energy from the
            results I achieve. I like to communicate clearly and am therefore
            'straight to the point'.
          </Desciption>
          <Buttons>
            <IconButton href="https://www.github.com/drtuup/">
              Github
              <GitHub sx={{ margin: "1rem", transform: "scale(2)" }} />
            </IconButton>
            <IconButton href="https://www.linkedin.com/in/ruben-claessens/">
              LinkedIn
              <LinkedIn sx={{ margin: "1rem", transform: "scale(2)" }} />
            </IconButton>
          </Buttons>
        </HomeLeft>
        <HomeRight>
          <Image src={me} />
          <Info>
            {<StyledPublicIcon />} &nbsp; - &nbsp; Based in the Netherlands
          </Info>
          <Info>
            {<StyledEmailIcon />} &nbsp; - &nbsp;
            <a href="mailto:claessensruben2812@gmail.com">
              claessensruben2812@gmail.com
            </a>
          </Info>
        </HomeRight>
      </View>
      <Footer />
    </React.Fragment>
  );
}
