import styled from "styled-components";
import NavItem from "./navitem";

const NavContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding: 1rem 0;
  outline: solid 1px ${(props) => props.theme.navbar};
  background-color: ${(props) => props.theme.background};
  height: fit-content;
  z-index: 999;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    gap: 1rem;
    position: sticky;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    position: sticky;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    position: sticky;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    position: sticky;
  }
`;

interface NavBarProps {
  children: React.ReactNode;
}

export default function Navbar({ children }: NavBarProps) {
  return (
    <NavContainer>
      <NavItem text="Home" />
      <NavItem text="Skills" />
      <NavItem text="Experience" />
      {children}
    </NavContainer>
  );
}
