export const lightTheme = {
    background: "#F3F3F4",
    dark: "#0074A6",
    navbar: "#0074A6",
    text: {
        primary: "#000000",
        secondary: "#FFFFFF",
    },
}
export const darkTheme = {
    background: "#000D13",
    dark: "#002B3D",
    navbar: "#FFFFFF",
    text: {
        primary: "#FFFFFF",
        secondary: "#FFFFFF",
    },

}