import styled from "styled-components";

const StyledSkillCard = styled.div`
  display: flex;
  padding: 2rem 5rem;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${(props) => props.theme.dark};
  padding-top: 4rem;
  flex-wrap: wrap;
  flex: 1 1;
  width: fit-content;
`;

const CardIconContainer = styled.div`
  transform: scale(5);
  color: ${(props) => props.theme.text.secondary};
  background: transparent;
  padding: 0.5rem;
`;

const Title = styled.h3`
  font-size: 3rem;
  margin: 2rem;
  font-weight: 900;
  color: ${(props) => props.theme.text.secondary};
`;

interface SkillCardProps {
  title: string;
  icon: React.ReactElement;
  children: React.ReactNode;
}

export function SkillCard({ title, icon, children }: SkillCardProps) {
  return (
    <StyledSkillCard>
      <CardIconContainer>{icon}</CardIconContainer>
      <Title>{title}</Title>
      {children}
    </StyledSkillCard>
  );
}

const StyledExperienceCard = styled.div`
  display: flex;
  padding: 2rem 5rem;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.dark};
  width: fit-content;
  gap: 2rem;
  flex: 1;
  align-self: stretch;
  border-radius: 20px;
`;

interface ExperienceCardProps {
  title: string;
  children: React.ReactNode;
}

export function ExperienceCard({ title, children }: ExperienceCardProps) {
  return (
    <StyledExperienceCard>
      <Title>{title}</Title>
      {children}
    </StyledExperienceCard>
  );
}
