import styled from "styled-components";
import React from "react";

const StyledTitle = styled.h1`
  margin: 0 auto;
  padding: 3rem;
  border-radius: 100vmax;
  background-color: ${(props) => props.theme.dark};
  color: ${(props) => props.theme.text.secondary};
  font-size: 4rem;
  width: fit-content;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 2.2rem auto;
    font-size: 3.7rem;
  }
`;

interface TitleProps {
  children: React.ReactNode;
}

export default function Title({ children }: TitleProps) {
  return <StyledTitle>{children}</StyledTitle>;
}
