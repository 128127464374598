import { IconButton } from "@mui/material";
import { DarkMode as DarkModeIcon } from "@mui/icons-material";
import styled from "styled-components";

interface ThemeSwitchProps {
  onClick: () => void;
}

const StyledDarkMode = styled(DarkModeIcon)`
  color: ${(props) => props.theme.navbar};
  scale: 2;
  aspect-ratio: 1/1;
`;

const StyledIconButton = styled(IconButton)`
  justify-self: flex-end;
  color: ${(props) => props.theme.navbar};
  aspect-ratio: 1;
`;

export function ThemeSwitch({ onClick }: ThemeSwitchProps) {
  return (
    <StyledIconButton onClick={onClick}>
      <StyledDarkMode />
    </StyledIconButton>
  );
}
