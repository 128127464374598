import styled from "styled-components";
import React from "react";
import Title from "../components/title";
import { SkillCard } from "../components/card";
import {
  Laptop as LaptopIcon,
  Person as PersonIcon,
  PersonOutline as PersonOutlineIcon,
} from "@mui/icons-material";
import { professionalSkills, frontend, backend, toolsAndDesign } from "../data";

const View = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    flex-direction: row;
    gap: 1.5rem;
    padding: 2rem;
    height: fit-content;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    flex-direction: row;
    padding: 2rem;
    height: fit-content;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 2rem;
    flex-direction: row;
    gap: 1.5rem;
    height: fit-content;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1440px) {
    padding: 2rem;
    height: fit-content;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0 0 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text.secondary};
  gap: 1rem;
  font-size: 2rem;
  margin: 0.5rem;
  color: ${(props) => props.theme.main};

  & svg {
    color: ${(props) => props.theme.text.secondary};
    transform: scale(1.5);
    margin: 0;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: inherit;
`;

const SubTitle = styled.h2`
  font-size: 1.8rem;
  margin: 2rem 0 1rem 0;
  text-decoration: underline;
  color: ${(props) => props.theme.text.secondary};
`;

const SkillContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  flex: 1 1;
`;

const SkillItem = styled.h4`
  font-size: 1.5rem;
  color: ${(props) => props.theme.text.primary};
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.background};
  border-radius: 10px;
  padding: 0.8rem;
  margin: 0;
`;

export default function SkillsView() {
  return (
    <React.Fragment>
      <View>
        <Title>Skills</Title>
        <CardContainer>
          <SkillCard title="Professional Skills" icon={<PersonOutlineIcon />}>
            <List>
              {professionalSkills.map((x) => (
                <ListItem key={x}>
                  <PersonIcon />
                  {x}
                </ListItem>
              ))}
            </List>
          </SkillCard>
          <SkillCard title="Development Skills" icon={<LaptopIcon />}>
            <SubTitle>Frontend development</SubTitle>
            <SkillContainer>
              {frontend.map((x) => (
                <SkillItem key={x}>{x}</SkillItem>
              ))}
            </SkillContainer>
            <SubTitle>Backend development</SubTitle>
            <SkillContainer>
              {backend.map((x) => (
                <SkillItem key={x}>{x}</SkillItem>
              ))}
            </SkillContainer>
            <SubTitle>Tools and design</SubTitle>
            <SkillContainer>
              {toolsAndDesign.map((x) => (
                <SkillItem key={x}>{x}</SkillItem>
              ))}
            </SkillContainer>
          </SkillCard>
        </CardContainer>
      </View>
    </React.Fragment>
  );
}
